<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            class="modal-extended"
            :show.sync="$store.state.contenedores.modalComponent"
            :title="tituloModal"
            :close-on-backdrop="false"
         
        >
            <CRow>
                <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.group')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :value="formComponent.TpCargoId"
                        :addInputClasses="{ 'is-invalid': $v.formComponent.TpCargoId.$error }"
                        @blur="$v.formComponent.TpCargoId.$touch()"
                        @input="formComponent.TpCargoId = $event.target.value"
                        :options="optionList"
                        :invalid-feedback="errorMessage($v.formComponent.TpCargoId)"
                        :is-valid="hasError($v.formComponent.TpCargoId)"
                    >
                    </CSelect>
                </CCol>
                 <CCol sm="12" lg="12" >
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="$t('label.componentName')"
                        v-model="$v.formComponent.TpCargoComponentName.$model"
                        @blur="$v.formComponent.TpCargoComponentName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formComponent.TpCargoComponentName.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formComponent.TpCargoComponentName)"
                        :is-valid="hasError($v.formComponent.TpCargoComponentName)"

                    >
                    </CInput>
                </CCol>
               <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :is-valid="formComponent.FgActCargoComponent"
                        v-if="!idIndentification"
                        v-model="formComponent.FgActCargoComponent"
                        :value.sync="formComponent.FgActCargoComponent"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="Component" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormComponent } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';

    //data
    function data() {
        return {
            formComponent:{
                TpCargoId:'',
                TpCargoComponentId:0,
                TpCargoComponentName:'',
                FgActCargoComponent:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nuevo')+' '+this.$t('label.component'),
            loading:false,
        }
    }
    //methods
    function cambio(e){
        this.formComponent.FgActCargoComponent = !this.formComponent.FgActCargoComponent;
    }
    function cerrarModal(){
        if(this.apiStateFormLoading || this.loading)
            return
        this.$store.state.contenedores.modalComponent = false;
    }
    function registerData(){
         try { 
                this.$v.formComponent.$touch();
            if (this.$v.formComponent.$pending || this.$v.formComponent.$error) {
                throw this.$t('label.errorsPleaseCheck');
             } 

                let { TpCargoComponentId,TpCargoId,TpCargoComponentName,FgActCargoComponent } = this.formComponent;
                const { dispatch } = this.$store;
                if(this.originalAct !== '' && TpCargoComponentId !== 0){
                    if(this.originalAct !== FgActCargoComponent){
                        this.$swal.fire(
                            alertPropertiesHelpers(this, {
                                text: `${this.$t('label.changeStatusQuestion')+' '} ${TpCargoComponentName}?`,
                            })
                        ).then((result) => {
                            if (result.isConfirmed) {
                                dispatch('contenedores/dataAccesory', { 
                                    TpCargoAccesoryId:TpCargoComponentId, 
                                    TpCargoId,
                                    TpCargoAccesoryName:TpCargoComponentName,
                                    FgActCargoAccesory:FgActCargoComponent,
                                    FgComponent:1
                                });
                            }else {
                                //this.$swal(this.$t('label.operationCancelled'));
                            }
                        })
                    }else{
                        dispatch('contenedores/dataAccesory', { 
                            TpCargoAccesoryId:TpCargoComponentId, 
                            TpCargoId,
                            TpCargoAccesoryName:TpCargoComponentName,
                            FgActCargoAccesory:FgActCargoComponent,
                            FgComponent:1
                        });
                    }
                }else{
                    dispatch('contenedores/dataAccesory', { 
                        TpCargoAccesoryId:TpCargoComponentId, 
                        TpCargoId,
                        TpCargoAccesoryName:TpCargoComponentName,
                        FgActCargoAccesory:FgActCargoComponent,
                        FgComponent:1
                    });
                }

        } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    //computed
    function idIndentification(){
        return this.formComponent.TpCargoComponentId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionList(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    function selectOptions (){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalComponent(newQuestion){
        if(newQuestion === false){
            this.formComponent.TpCargoComponentId=0;
            this.formComponent.TpCargoComponentName='';
            this.formComponent.TpCargoId = '';
            this.originalAct = '';
            this.tituloModal =  this.$t('label.nuevo')+' '+this.$t('label.component');
            this.formComponent.FgActCargoComponent=true;
            this.$store.state.contenedores.filtroCargo = '';
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            this.tituloModal =  this.$t('label.nuevo')+' '+this.$t('label.component');
            if(this.idState !== 0){
                const id = this.idState;
                this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'TpCargoComponent-by-id',{ TpCargoAccesoryId: id }, '' ).then(response => {
                    try {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                this.tituloModal = this.$t('label.edit')+' '+this.$t('label.component')+': '+Information[0].TpCargoAccesoryName;
                                this.formComponent.TpCargoComponentId= Information[0].TpCargoAccesoryId;
                                this.formComponent.TpCargoId= Information[0].TpCargoId;
                                this.formComponent.TpCargoComponentName= Information[0].TpCargoAccesoryName;
                                this.originalAct = Information[0].FgActCargoAccesory;
                                this.formComponent.FgActCargoComponent= Information[0].FgActCargoAccesory;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }
                            this.$v.$touch();
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalComponent = false;
                        }
                    } catch (error) {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalComponent = false;
                        this.$store.commit('contenedores/messageMutation', err);
                    }
                }).catch(err => {
                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                    this.$store.state.contenedores.modalComponent = false;
                    this.$store.commit('contenedores/messageMutation', err);
                });
            }else{
                if(this.filtroCargo !== ''){
                    this.formComponent.TpCargoId= this.filtroCargo;
                }
            }
        }
    }

    export default {
        name: 'app-modal-Component',
        data,
        validations(){ return FormComponent() },
        methods:{
            registerData,
            cambio,
            cerrarModal,
            //errorMessage,
        },
        mixins: [GeneralMixin ],
        directives: UpperCase,
        computed:{
            idIndentification,
            optionList,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalComponent: state=> state.contenedores.modalComponent,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                filtroCargo: state => state.contenedores.filtroCargo,
            })
        },
        watch:{
            modalComponent
        }
    }
</script>

